.draggableBox {
  overflow: hidden;
  border-width: 2px;
  border-style: dashed;
  border-color: transparent;
  transition: border-color 2s ease-out;
}

.draggableBox.highlighted {
  border-color: #f87733;
}

.shiftedGrid {
  margin-left: -16px;
}

.dragIcon {
  margin-right: 12px;
  margin-top: 18px;
  cursor: grab;
  height: 30px;
  width: 30px;
  color: #f87733;
}

.common-fields,
.attributes-list-title {
  margin-left: 28px;
  margin-top: 10px;
}
