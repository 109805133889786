.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: #ffffff;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'Inter-Regular'; /*Can be any text*/
  src: local('Inter-Regular'),
    url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter-SemiBold'; /*Can be any text*/
  src: local('Inter-SemiBold'),
    url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'MatterSQ-Regular'; /*Can be any text*/
  src: local('MatterSQ-Regular'),
    url('./assets/fonts/Matter/MatterSQ-Regular.ttf') format('truetype');
}
