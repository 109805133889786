.vanilla-jsoneditor-react {
  display: flex;
  flex: 1;
  --jse-theme-color: #f87733;
  --jse-theme-color-highlight: #fbab7f;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: 28px;
  margin-left: 28px;
}
