.archive-btn {
  margin-top: -35px;
  margin-right: 35px;
  margin-left: 0px;
  margin-bottom: 6px;
}

.add-btn {
  right: 48px;
  top: 85px;
  left: auto;
  position: fixed;
}
